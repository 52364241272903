export const authenticateUser = () => {
    const value = localStorage.getItem('isLoggedIn')
    return value === 'TRUE'
}

export const logoutUser = () => {
    localStorage.clear();
}

export const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
}
