import React from 'react';
import StarRating from './StarRating'
import { truncateText } from '../../utilities/utility'
import { PRODUCT_PAGE_URL } from '../../utilities/constants'

interface Product {
    product_id: number
    product_name: string;
    product_description: string;
    product_image: string;
}

interface CardListProps {
    products: Product[];
}

const redirectToDetailsPage = (id: number) => {
    window.open(`${PRODUCT_PAGE_URL}${id}`, "_blank")
}

const CardList: React.FC<CardListProps> = ({ products }) => {
    return (
        <div className='card-list'>
            {products && products.map((product, index) => (
                <div className='product-card' key={index} onClick={() => redirectToDetailsPage(product.product_id)}>
                    <p style={{ fontWeight: 'bold' }}>{product.product_name}</p>
                    <p>Product ID: {product.product_id}</p>
                    {
                        product.product_image ? <>
                            <img src={product.product_image} width="100%" />
                        </>
                            :
                            <></>
                    }   
                    {
                        product.product_description ? <>
                            <p title={product.product_description}>{truncateText(product.product_description, 80)}</p>
                        </>
                            :
                            <></>
                    }
                </div>
            ))}
        </div>
    );
};

export default CardList;
