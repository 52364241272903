import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from './Home';
import { Search } from './components/search/Search';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </div>
  );
}

export default App;
