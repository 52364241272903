import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchIcon } from '../../assets/search';
import CardList from './CardList';
import { authenticateUser } from '../../utilities/utility';
import { BASE_URL } from '../../utilities/constants';
import './Search.css';


export function Search() {
    const navigate = useNavigate();
    // const count = useAppSelector((state) => state.counter.value)
    // const dispatch = useAppDispatch()
    const [query, setQuery] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [knowledgeData, setKnowledgeData] = useState<any>(null);
    const [error, setError] = useState<string>('');

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const fetchData = async (text: any) => {
        setLoader(true)
        try {
            const url = new URL('/knowledgeSearch', BASE_URL);
            url.search = new URLSearchParams({query: text, detail: "true"}).toString();
            const knowledgeAPI = fetch(url.toString())
            knowledgeAPI
                .then(response => {
                    if (!response.ok) {
                        setLoader(false)
                        if (response.status === 400) {
                            throw new Error('Bad request - 400');
                        } else if (response.status === 401) {
                            throw new Error('Unauthorized - 401');
                        } else if (response.status === 404) {
                            throw new Error('Not found - 404');
                        } else if (response.status === 500) {
                            throw new Error('Internal server error - 500');
                        } else {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                    }
                    return response.json()
                })
                .then((data: any) => {
                    setKnowledgeData(data)
                    setTimeout(() => {
                        setLoader(false)
                    }, 500)
                })
                .catch((error) => {
                    setError('Error fetching data: ' + error)
                    console.error('Error fetching data:', error);
                    setLoader(false)
                })
        } catch (error) {
            setError('Error fetching data: ' + error)
            console.error('Error fetching data:', error);
            setLoader(false)
        }
    };


    const onSearch = async () => {
        setError('')
        setKnowledgeData([])
        try {
            // await getTraditionalData(query)
            // await getKnowledgeData(query)
            await fetchData(query)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const handleKeyUp = (e: any) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    return (
        <div className='search-page-container'>
            <section className='search-section'>
                <div className='search-box-container'>
                    <input type="text" placeholder="Search DaaX.AI" value={query} onChange={handleOnChange} onKeyUp={handleKeyUp} />
                    <SearchIcon onClick={onSearch} className='search-icon' />
                </div>
            </section>
            {
                loader ? <div><span className="loader"></span></div>
                    :
                    <section className='result-section'>
                        {
                            knowledgeData &&
                            <>
                                <div className='result-container'>
                                    <div className='result-items'>
                                        {
                                            knowledgeData.length > 0 ? <div className='item-container'>
                                                <CardList products={knowledgeData} />
                                            </div>
                                                :
                                                <EmptyData />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        { 
                            error && <div style={{ color: 'red' }}>{error}</div>
                        }
                    </section>
            }
        </div>
    )
}


function EmptyData() {
    return (
        <div className='result-empty-container'>
            <p>No Results Found</p>
        </div>
    )
}